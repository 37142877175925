import React from 'react';
import MDReactComponent from 'markdown-react-js';

export const ModuleSliderImage = props => {
  let imgPath = require(`../images/${props.image.file}`);
  return (
    <div>
      <figure
        style={{
          paddingBottom: '50%',
          position: 'relative',
          margin: '0 5px 20px',
          border: '2px solid #ccc'
        }}
      >
        <img
          alt={props.text}
          src={imgPath}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        />
      </figure>
      {props.text && <MDReactComponent text={props.text} />}
    </div>
  );
};
