import React from 'react';
import MDReactComponent from 'markdown-react-js';
import style from './module-text-image.module.scss';
import { isFunction } from '../utils';

const noop = () => {};

export const ModuleText = props => {
  const { onClick, title, sections } = props;

  const hasClickHandler = isFunction(onClick);

  let className = style.module;
  if (hasClickHandler) {
    className += ' has-fadeout';
  }

  return (
    <div
      className={className}
      onClick={hasClickHandler ? onClick : noop}
      style={{ cursor: hasClickHandler ? 'pointer' : 'normal' }}
    >
      {title && <h2>{title}</h2>}
      {sections.map((props, i) => {
        return <MDReactComponent key={i} text={props.text} />;
      })}
    </div>
  );
};
