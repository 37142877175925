import React, { Component } from 'react';
import style from './module-video.module.scss';
import MDReactComponent from 'markdown-react-js';

let videoPlayer = null;
class ModuleVideo extends Component {
  constructor(props) {
    super(props);
    this.videoElementRef = React.createRef();
  }

  componentWillReceiveProps(props) {
    if (!props.active) {
      // console.log('now');
      // console.dir(this.videoElementRef);
      // const src = this.videoElementRef.src;
      // this.forceUpdate();
      // this.videoElementRef.pauseVideo();
      // try {
      //   this.videoElementRef.current.contentWindow.postMessage(
      //     '{"event":"command","func":"' + 'pauseVideo' + '","args":""}',
      //     '*'
      //   );
      // } catch (e) {
      //   console.error(e);
      // }
    }
  }

  render() {
    const props = this.props;
    switch (props.type) {
      case 'video-yt':
        let videoLink = props.active
          ? props.link + '?version=3&enablejsapi=1&playerapiid=ytplayer'
          : '';
        videoPlayer = (
          <div className={style.videoElement}>
            <iframe
              width="560"
              height="315"
              src={videoLink}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title={props.text}
              ref={this.videoElementRef}
              style={{ border: '2px solid #ccc' }}
            />
            <MDReactComponent text={props.text} />
          </div>
        );
        break;
      case 'video-fs':
        videoPlayer = (
          <div className={style.videoElement}>
            <video
              width="560"
              height="315"
              ref={this.videoElementRef}
              controls
              style={{ border: '2px solid #ccc' }}
            >
              <source src={props.link} type="video/mp4" />
            </video>
            <MDReactComponent text={props.text} />
          </div>
        );
        break;
      default:
        return null;
    }

    return <div className={style.video}>{videoPlayer}</div>;
  }
}

export { ModuleVideo };
